import { ILicenseTypes } from "./utils.model";

export interface IPaymentToCreate {
    id: string,
    tipo: PaymentType,
    motivo_cancelamento: CancellationReason,
    detalhes_cancelamento: string,
    referente: ReferenceType,
    dataPagamento: string,
    valor: string,
    recorrenteId: string,
    transactionId: string,
    tipoLicencaRecorrenteId: string,
    dataAtivacao: string | null | undefined,
    dataExpiracao: string | null | undefined,
    coupon: string,
    plan: string,
    dataExpiracaoPlano: string,
    dataExpiracaoPagto: string,
    forma: PaymentMethod,
    detalhes: string,
    codigo: string,
    rede: string,
    operacao: OperationType,
    parcelas: number,
    tipoLicencaId: string,
    pagamentoRecorrenteId: string,
};

export interface IPayment {
    id: string,
    tipo: string,
    created: ICreated,
    dataPagamento: string,
    valor: string,
    licenca: ILicense,
    cancelamento: ICancel,
    recorrente: IRecurrent,
    invoice?: IInvoice,
    coachId: string,
    coupon?: string,
    email: string,
};

export interface IInvoice {
    id: string,
    userId: string,
    paymentId: string,
    emissionId: string,
    cnpj: string,
    documentType: string,
    documentValue: string,
    name: string,
    email: string,
    type: string,
    status: string,
    createdAt: string,
    updatedAt: string,
    message: string,
    address: {
        state: string,
    },
    service: {
        description: string,
        aliquota: number,
        value: number,
        paymentDate?: number
    },
    invoice: {
        message: string,
        lote: number,
        numero: number,
        emissao: string,
        situacao: string,
        numeroNfse: string,
        serie: string,
    }
};



interface IRecurrent {
    index: number
    pagamentoRecorrente: IPaymentRecurrent
};

interface IPaymentRecurrent {
    created: any
    dataAtivacao: string
    dataExpiracao: string
    duracao: number
    id: string
    recorrenteId: string
    tipo: string
    tipoLicenca: any
    coupon?: string
}

interface ICreated {
    date: string,
    timezone_type: number,
    timezone: string
};

interface ICancel {
    id: string,
    created: string,
    motivo: string,
    detalhes: string
};

export interface ILicense {
    id: string,
    created: string,
    name: string
    dataAtivacao: string,
    dataExpiracao: string,
    dataAtivacaoGlobal: string,
    dataExpiracaoGlobal: string,
    tipoLicenca: ILicenseTypes,
    duracao: number,
    duracaoGlobal: number,
    valid: boolean,
    recorrenteWarning: boolean
}

export enum OperationType {
    CREDIT = 'CREDITO',
    DEBIT = 'DEBITO'
};

export enum PaymentMethod {
    CHECK = 'CHEQUE',
    CASH = 'CASH',
    TRANSFER = 'TRANSFERENCIA',
    SPECIFY = 'ESPECIFICAR'
}

export enum ReferenceType {
    ACQUISITION = 'AQUISICAO',
    CHARGE = 'COBRANCA'
};

export enum PaymentType {
    CIELO = 'cielo',
    CIELO_RECURRENT = 'cielo_recorrente',
    PAG_SEGURO = 'pagseguro',
    PAG_SEGURO_RECURRENT = 'pagseguro_recorrente',
    PAY_PAL = 'paypal',
    PAY_PAL_RECURRENT = 'paypal_recorrente',
    BOLETO = 'boleto',
    ADJUSTMENT = 'ajuste',
    CARD = 'cartao',
    OTHER = 'outro'
};

export enum CancellationReason {
    REGISTRATION_ERROR = 'ERRO_DE_CADASTRO',
    CUSTOMER_REQUEST = 'SOLICITACAO_CLIENTE',
    FREE_REVOCATION = 'REVOGACAO_GRATUIDADE',
    EXPERIMENTAL_REVOCATION = 'REVOGACAO_EXPERIMENTAL',
    LEGACY_DEACTIVATION = 'DESATIVACAO_LEGADO',
    OTHER = 'OUTRO'
};

export interface IQueryPayments {
    page: number;
    pageSize: number;
    search?: string | null;
    from?: string | null;
    to?: string | null;
    types: PaymentTypes[];
}

export interface IListPayments {
    payments: IPayment[],
    total: number,
}

export enum PaymentTypes {
    BOLETO = 'Boleto',
    PIX = 'Pix',
    PAGSEGURO = 'PagSeguro',
    PAYPAL = 'Paypal',
    MUNDIPAGG = 'MundiPagg',
    CARTAO = 'Cartao',
    OUTRO = 'Outro',
    AJUSTE = 'Ajuste',
    LEGADO = 'Legado',
    EXPERIMENTAL = 'Experimental',
    CIELO = 'Cielo'
};