import React, { useState } from 'react';
import { Table, Icon, Tag, Modal, Typography, Pagination } from 'antd';
import { IInvoice, IPayment } from '../../../models/payment.model';
import { formatDate } from '../../../utils/moment.functions';
import moment from 'moment-timezone';
import { permissionKeys } from '../../../utils/permissions';
import RestrictedComponent from '../../../components/restricted-component/RestrictedComponent';
import Invoice from '../invoice/Invoice';

import './PaymentList.scss';
import { history } from '../../../config/Store.config';
import { routes } from '../../../utils/routers.constants';

const { Text } = Typography;
interface IProps {
    payments: IPayment[],
    isLoading: boolean,
    page: number,
    pageSize: number,
    onCancelPayment(payment: IPayment): void,
    checkPermissions(permissions: string[]): boolean,
    idCoach?: string,
    total: number,
    onChangePage: (page: number) => void,
    onChangePageSize: (pageSize: number) => void,
};

const PaymentList = ({
    payments,
    isLoading,
    onCancelPayment,
    checkPermissions,
    page,
    pageSize,
    idCoach,
    total,
    onChangePage,
    onChangePageSize,
 }: IProps) => {

    const [openInvoice, setOpenInvoice] = useState<IInvoice|undefined>(undefined);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a: IPayment, b: IPayment) => a.tipo > b.tipo ? 1 : -1,
        },
        {
            title: 'Tipo de Pagto',
            dataIndex: 'tipo',
            key: 'tipo',
            sorter: (a: IPayment, b: IPayment) => a.tipo > b.tipo ? 1 : -1,
        },
        {
            title: 'Data',
            dataIndex: 'dataPagamento',
            key: 'dataPagamento',
            sorter: (a: IPayment, b: IPayment) => a.dataPagamento > b.dataPagamento ? 1 : -1,
            render: (text: any) => formatDate(text)
        },
        {
            title: 'Valor',
            dataIndex: 'valor',
            key: 'valor',
            sorter: (a: IPayment, b: IPayment) => a.valor > b.valor ? 1 : -1,
        },
        {
            title: 'Cupom',
            key: 'coupon',
            sorter: (a: IPayment, b: IPayment) => a.valor > b.valor ? 1 : -1,
            render: (payment: IPayment) => payment.coupon ? payment.coupon : payment.recorrente ? payment.recorrente.pagamentoRecorrente.coupon : undefined,
        },
        {
            title: 'Recorrente',
            dataIndex: 'recorrente',
            key: 'recorrenteTipo',
            render: (recorrent: any) => <span>{recorrent && recorrent.pagamentoRecorrente.tipo}</span>
        }, {
            title: 'Recorrente ID',
            dataIndex: 'recorrente',
            key: 'recorrenteId',
            render: (recorrent: any) => <span>{recorrent && recorrent.pagamentoRecorrente.recorrenteId}</span>
        }, {
            title: 'Parcelas',
            dataIndex: 'recorrente',
            key: 'recorrenteParcelas',
            render: (recorrent: any) => <span>{recorrent && `${recorrent.index}/${recorrent.pagamentoRecorrente.duracao}`}</span>
        }, {
            title: 'Licença',
            dataIndex: 'licenca.tipoLicenca.name',
            key: 'licenca.tipoLicenca.name',
            sorter: (a: any, b: any) => a.licenca.tipoLicenca.name > b.licenca.tipoLicenca.name ? 1 : -1,
        }, {
            title: 'Ativação',
            dataIndex: 'licenca.dataAtivacao',
            key: 'licenca.dataAtivacao',
            sorter: (a: IPayment, b: IPayment) => a.licenca.dataAtivacao > b.licenca.dataAtivacao ? 1 : -1,
            render: (text: any) => formatDate(text)
        }, {
            title: 'Expiração',
            dataIndex: 'licenca.dataExpiracao',
            key: 'licenca.dataExpiracao',
            sorter: (a: IPayment, b: IPayment) => a.licenca.dataExpiracao > b.licenca.dataExpiracao ? 1 : -1,
            render: (text: any) => formatDate(text)
        },{
            title: 'Ativação Global',
            dataIndex: 'licenca.dataAtivacaoGlobal',
            key: 'licenca.dataAtivacaoGlobal',
            sorter: (a: IPayment, b: IPayment) => a.licenca.dataAtivacaoGlobal > b.licenca.dataAtivacaoGlobal ? 1 : -1,
            render: (text: any) => formatDate(text)
        }, {
            title: 'Expiração Global',
            dataIndex: 'licenca.dataExpiracaoGlobal',
            key: 'licenca.dataExpiracaoGlobal',
            sorter: (a: IPayment, b: IPayment) => a.licenca.dataExpiracaoGlobal > b.licenca.dataExpiracaoGlobal ? 1 : -1,
            render: (text: any) => formatDate(text)
        }, {
            title: 'Status',
            dataIndex: 'licenca.valid',
            key: 'licenca.valid',
            sorter: (a: IPayment, b: IPayment) => a.licenca.dataExpiracao > b.licenca.dataExpiracao ? 1 : -1,
            render: (isValid: any, payment: IPayment) => {
                let status = '';
                if (payment.cancelamento) {
                    status = "CANCELADO";
                } else if (payment.licenca.recorrenteWarning === true) {
                    status = "ATENÇÃO";
                } else if (isValid === true) {
                    status = "VÁLIDO";
                } else if (moment(payment.licenca.dataAtivacao).isAfter(moment())) {
                    status = "PRONTO";
                } else {
                    status = !moment(payment.licenca.dataExpiracaoGlobal).isAfter(moment()) ? 'ENCERRADO' : "EXPIRADO";
                };

                return (
                    <Tag className={`tags-status ${status}`}>
                        {status.toUpperCase()}
                    </Tag>
                )
            }
        }, {
            title: 'Ações',
            key: 'action',
            render: (payment: IPayment) => (
                <span className="icons-action">
                    {
                        !idCoach && (
                            <Icon
                                type="tool"
                                onClick={() => history.push(routes.PAYMENTS(payment.coachId))}
                            />
                        )
                    }
                    {
                        payment.invoice && (
                            <Icon
                                type="database"
                                onClick={() => setOpenInvoice(payment.invoice)}
                            />
                        )
                    }
                    {payment.cancelamento ?
                        <Icon
                            className="canceled"
                            type="issues-close" />
                        : <RestrictedComponent hasPermission={checkPermissions([permissionKeys.PAYMENTS, permissionKeys.USER_PAYMENT_REFUNDEND])}>
                            <Icon
                                className="active"
                                type="close-circle"
                                onClick={() => onCancelPayment(payment)}
                            />
                        </RestrictedComponent>}
                </span>
            )
        },
    ];

    if (!idCoach) {
        columns.splice(1, 0,{
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            sorter: (a: IPayment, b: IPayment) => a.tipo > b.tipo ? 1 : -1,
        });
    }


    return (
        <>
            {openInvoice && openInvoice.id && (
                <Modal
                    width={700}
                    title={`Nota ${openInvoice.email}`}
                    visible={true}
                    footer={false}
                    maskClosable={false}
                    onCancel={() => setOpenInvoice(undefined)}
                >
                    <Invoice {...(openInvoice || {})} />
                </Modal>
                )
            }

            <Table
                loading={isLoading}
                columns={columns}
                className={"table-payments"}
                rowClassName = {(payment: IPayment) => payment.cancelamento ? 'row-canceled-payments' : 'row-payments' }
                dataSource={payments.map((payment: IPayment) => ({ ...payment, key: payment.id }))}
                pagination={idCoach ? undefined : false}
            />

            {
                !idCoach && (
                    <>
                        <div style={{ textAlign: 'right', marginTop: 10, marginBottom: -15 }}>
                            <Text><b>{total}</b> pagamentos encontrados</Text>
                        </div>
                        <Pagination
                            className="all-payments-pagination"
                            showSizeChanger
                            onShowSizeChange={(_, pageSize) => onChangePageSize(pageSize)}
                            defaultPageSize={pageSize}
                            defaultCurrent={page}
                            current={page}
                            total={total}
                            pageSizeOptions={['10', '25', '50', '100']}
                            onChange={page => onChangePage(page)}
                        />
                    </>
                )
            }
        </>
    );
};

export default PaymentList;