import React, { useEffect, useState } from 'react';
import { ICoache, CoachingType, ICoupons } from '../../../models/coache.model';
import { Table, Icon, Pagination, Tag, Tooltip, Typography, Popover } from 'antd';
import { history } from '../../../config/Store.config';
import { routes } from '../../../utils/routers.constants';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import { changePageSize, changePage, getCoache, changeOrder } from '../../../reducers/coaches.reducer';
import SearchFilter from './search-filter/SearchFilter';
import NewNotificationModal from '../../notifications-list/NewNotificationModal';
import { findClass, formatDate } from '../../../utils/moment.functions';
import { isEmpty } from '../../../utils/utils.functions';
import {permissionKeys} from '../../../utils/permissions';
import RestrictedComponent from '../../../components/restricted-component/RestrictedComponent';

import './CoacheList.scss';

const { Text } = Typography;

const allActionPermissionKeys = [
    permissionKeys.PAYMENTS,
    permissionKeys.USER_PAYMENT,
    permissionKeys.USER_COACHES_EDIT,
];

interface ICoacheListProps {
    coaches: ICoache[],
    loading: boolean,
    total: number,
    getCoaches(): void,
    getClass(): void,
    onEditCoach(coach: ICoache): void,
    checkPermissions(permissions: string[], atLeastOne?: boolean): boolean,
};

interface INotificationModal {
    showModal: boolean,
    coachId: string,
}

const CoacheList = ({ coaches, loading, total, getCoaches, getClass, checkPermissions }: ICoacheListProps) => {
    const [notificationModal, setNotificationModal] = useState<INotificationModal>({
        showModal: false,
        coachId: "",
    });
    const { page, pageSize, allClasses} = useSelector(({ coaches }: IState) => ({ page: coaches.page, pageSize: coaches.pageSize, allClasses: coaches.allClass }))
    const dispatch = useDispatch();

    useEffect(() => {
        getCoaches();
        getClass()
        // eslint-disable-next-line
    }, [page, pageSize]);

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nickname',
            key: 'nickname',
            sorter: true,
            ellipsis: true,
            width: 140,
            render: (_: any, record: any) => {
                const { given_name: givenName, family_name: familyName, nickname } = record;

                if (nickname && givenName && familyName) {
                    return `${nickname} (${givenName} ${familyName})`;
                } else if (nickname) {
                    if (givenName || familyName) {
                        return `${nickname} (${givenName || familyName})`;
                    } else {
                        return nickname;
                    }
                } else if (givenName && familyName) {
                    return `${givenName} ${familyName}`;
                } else if (givenName || familyName) {
                    return `${givenName || familyName}`;
                } else {
                    return '(sem nome)';
                }
            },
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            ellipsis: true,
            width: 120,
        }, {
            title: 'Telefone',
            dataIndex: 'phone_number',
            key: 'phone_number',
            sorter: true,
            width: 140,
        }, {
            title: 'Coupon',
            dataIndex: 'coach.coupons',
            key: 'coach.coupons',
            width: 120,
            render: (coupons: ICoupons[] = []) => {
                let newCoupons = coupons.filter(e => e.coupon);
                let current: ICoupons = newCoupons.sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at))[0];
                const content = (
                    <div className='container-coupons-table' >
                        <table>
                            <thead>
                                <tr>
                                    <th>Nome </th>
                                    <th>Data </th>
                                </tr>
                            </thead>
                            <tbody>
                                {newCoupons.map((e, index) => (
                                    <tr key={index} >
                                        <td>{e.coupon}</td>
                                        <td>{formatDate(e.created_at)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
                return (
                    <>
                        <Popover
                            className='container-popover-coupons'
                            content={content}
                            title='cupons'
                        >
                            <span >
                                {current && current.coupon}
                            </span>
                        </Popover>

                    </>
                )
            }
        }, {
            title: 'Turma',
            key: 'turma',
            width: 100,
            render: (coache: ICoache) => {
                return findClass(allClasses, coache.coach.enrollment);
            },
        }, {
            title: 'Unidade',
            dataIndex: 'coach.unit',
            key: 'coach.unit',
            sorter: true,
            width: 130,
        }, {
            title: 'Atribuições',
            dataIndex: 'coach.specializations',
            key: 'coach.specializations',
            sorter: true,
            width: 130,
            render: (specializations: CoachingType[]) => (
                <>
                    {(specializations || []).filter(s => !s.startsWith('hour')).map(tag => (
                        <Tooltip key={`tooltip-${tag}}`} title={tag.toUpperCase()}>
                            <Tag key={`tag-${tag}}`} className={`tags-status ${tag}`}>
                                {tag[0].toUpperCase()}
                            </Tag>
                        </Tooltip>
                    ))}
                </>
            ),
        }, {
            title: 'Data de encerramento',
            dataIndex: 'coach.license_expiration',
            key: 'coach.license_expiration',
            sorter: true,
            width: 120,
            render: (text: string) => text ? formatDate(text) : ''
        }, {
            title: 'Status',
            dataIndex: 'coach.status',
            key: 'coach.status',
            sorter: true,
            width: 120,
            render: (text: string) => text ? (
                <Tag className={`tags-status ${text}`}>
                    {text === 'RECORRENTE_PERIODO_EXTRA' ? 'Dias Adicionais' : text.toUpperCase()}
                </Tag>
            ) : null
        }, {
            title: 'Ações',
            key: 'action',
            width: 140,
            render: (coach: ICoache) => (
                <span className="icons-action">
                    {(coach.coach || !isEmpty(coach.coach)) &&
                        (<RestrictedComponent hasPermission={checkPermissions([permissionKeys.PAYMENTS, permissionKeys.USER_PAYMENT])}>
                            <Icon type="credit-card" onClick={() => history.push(routes.PAYMENTS(coach.id))} />
                        </RestrictedComponent>
                    )}
                    <RestrictedComponent hasPermission={checkPermissions([permissionKeys.USER_COACHES_EDIT])}>
                        <Icon
                            type="edit"
                            onClick={() => dispatch(getCoache(coach.id))}
                        />
                    </RestrictedComponent>
                    <RestrictedComponent hasPermission={checkPermissions([permissionKeys.USER_COACHES_NOTIFICATION])}>
                        <Icon type="bell" onClick={() => setNotificationModal({ coachId: coach.id, showModal: true })} />
                    </RestrictedComponent>
                </span>
            )
        },
    ];

    if (!checkPermissions(allActionPermissionKeys, true)) {
        columns.pop();
    }

    return (
        <>
            <SearchFilter classes={allClasses}/>
            <Table
                className="coach-list"
                pagination={false}
                loading={loading}
                rowClassName={(coach: ICoache) => (coach.coach && (coach.coach.status && coach.coach.status === "INATIVO")) ? 'row-canceled' : 'row'}
                columns={columns}
                dataSource={coaches.map((coache: ICoache) => ({ ...coache, key: coache.id }))}
                sortDirections={['ascend', 'descend', 'ascend']}
                onChange={(c1, c2, sorter) => dispatch(changeOrder(sorter.field, sorter.order.replace('end', '')))}
            />
            <div style={{ textAlign: 'right', marginTop: 10, marginBottom: -15 }}>
                <Text><b>{total}</b> coaches encontrados</Text>
            </div>
            <Pagination
                className="coaches-pagination"
                showSizeChanger
                onShowSizeChange={(_, pageSize) => dispatch(changePageSize(pageSize))}
                defaultPageSize={pageSize}
                defaultCurrent={page}
                current={page}
                total={total > 10000 ? 10000 : total}
                pageSizeOptions={['10', '25', '50', '100']}
                onChange={page => dispatch(changePage(page))}
            />

            {notificationModal.showModal &&
                <NewNotificationModal
                    closeModal={() => setNotificationModal({...notificationModal, showModal: false})}
                    recipient={notificationModal.coachId}
                />
            }
        </>
    );
};

export default CoacheList;