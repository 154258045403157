export const routes = {
    ROOT: '/',
    DASHBOARD: '/dashboard',
    VIDEOS: '/dashboard/videos',
    CATEGORIES: '/dashboard/categories',
    RESOURCES: '/dashboard/resources',
    COACHES: '/dashboard/coaches',
    COACHEES: '/dashboard/coachees',
    USERS: '/dashboard/users',
    PAYMENTS: (id = ':id'): string => `/dashboard/coaches/${id}/payments`,
    PAYMENTS_LIST: '/dashboard/coaches/payments',
    COACHES_INTEGRATIONS: '/dashboard/coaches/integrations',
    LOGIN: '/login',
    CONTACT_US: '/dashboard/contact',
    PANEL_CONTROL: '/dashboard/panelcontrol',
    PANEL_CONTROL_CLASSES: '/dashboard/panelcontrol/classes',
    PANEL_CONTROL_COACHES: '/dashboard/panelcontrol/coaches',
    PANEL_CONTROL_COACHING : '/dashboard/panelcontrol/coaching',
    PANEL_CONTROL_GRAPH: '/dashboard/panelcontrol/graph',
    PROFILE_COACHES: '/dashboard/profile_coaches',
    REGISTER_UNITS: '/dashboard/register_units',
    URL_SHORTENERS: '/dashboard/url_shorteners',
    BLOCKLIST: '/dashboard/blocklist',
    NOTIFICATIONS_LIST: '/dashboard/notifications_list',
    ADMINISTRATORS: '/dashboard/administrators',
    COACHES_REPORT: '/dashboard/reports/coaches',
    EXPERIMENTAL_SESSION_REPORT: '/dashboard/reports/experimentalsession',
    COACHING_HOURS_REPORT: '/dashboard/reports/coaching_hours',
    COACHING_CYCLE_REPORT: '/dashboard/reports/coaching_cycle',
    INVOICES_REPORT: '/dashboard/reports/invoices',
    AUDIT: '/dashboard/audit',
    EVALUATION_REPORT: '/dashboard/reports/evaluation',
    MONTHLY_COACHING: '/dashboard/reports/monthly_coaching',
    SCIS_CLASSES: '/dashboard/scis_classes',
    SALES_REGISTRATIONS_LIST: '/dashboard/sales/registrations',
    SALES_REGISTRATION_DETAIL: (id = ':id'): string => `/dashboard/sales/registrations/${id}`,

};
