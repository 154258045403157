import { Get as _Get, Post as _Post, Patch as _Patch, Put, Delete } from "./apiBase"
import { urls, settingsAuth, urlApiV1 } from "./urls.constants"
import { IResource, IFile } from "../models/resources.model"
import { ICenter, Permissions, ILicenseTypes, ICountry, ITimezone, IStates, ILocale } from "../models/utils.model";
import { IListPayments, IPayment, IPaymentToCreate, IQueryPayments } from "../models/payment.model";
import { ICoache, IUnit } from "../models/coache.model";
import { IUser } from "../models/user.model";
import { IPreviewInformation, IImportationForm, ICoachImport, ICoachPreview, ICoachToSave } from "../models/import.model";
import { IMessage } from "../models/contact.modal";
import { IDashboardData } from "../models/panelcontrol.model";
import { IRequest } from "../models/panelcontrol.coaching.model";
import { IGraphRequest, IGraphRequestAllYear } from "../models/panelcontrol.graph.model";
import { IRequestProfile } from "../models/profile.modal";
import { IRequestUnits, IUnits } from "../models/register-units.model";
import CredentialsHelper from "./credentials-helper";
import { ICoacheeDetails } from "../models/coachee.model";
import { IBlockList } from "../models/blockList.model";
import { INotificationsList, IPostNotification } from "../models/notificationsList.model";
import {IAdmin, IQueryStringList} from "../models/administrator.model";
import { IReport, IReportQueryStringList, IDownloadRequest, reportTypes } from "../models/report.model";
import { IAudit } from "../models/audit.model";
import { ICategory } from "../models/categories.model";
import { IVideo } from "../models/video.model";
import axios from 'axios';
import { IExtraArgs } from "../reducers/coaching-evaluations-reports.reducer";
import { IExtraArgsEs } from "../reducers/experimental-session-report.reducer";
import { ICoacheScisClasses, IUnitScisClasses } from "../models/scis-classes.model";
import { IRequestClasses } from "../models/panelcontrolClasses.model";
import { IEditSalesRegistrationResponse, ISalesRegistrationItem } from "../models/sales-registration.model";

/**
 * Auth Api
 */

export const getTokenFromCode = (code: string): Promise<any> => {
    const body = {
        code,
        grant_type: 'authorization_code',
        client_id: settingsAuth.client_id,
        machine: window.navigator.userAgent,
        scope: 'admin',
        redirect_uri: settingsAuth.redirect_uri_login,
    };

    return _Post(`${urls.TOKEN}`, body)
}

async function RefreshToken(): Promise<void> {
    const { access_token, refresh_token } = await _Post(urls.TOKEN, {
        grant_type: 'refresh_token',
        refresh_token: CredentialsHelper.refreshToken(),
        client_id: settingsAuth.client_id,
        machine: window.navigator.userAgent,
    });
    CredentialsHelper.update(access_token, refresh_token);
}

export const listPermissions = (): Promise<Permissions[]> => {
    return Get(urls.PERMISSIONS);
};


/**
 * Resources API
 */

export const listResources = (): Promise<IResource[]> => {
    return Get(urls.RESOURCES_LIST)
};

export const fetchResource = (id: string): Promise<IResource> => {
    return Get(`${urls.RESOURCES_LIST}/${id}`)
};

export const fetchFile = (id: string): Promise<IFile> => {
    return Get(`${urls.FILES}/${id}`);
};

/**
 * User API
 */

export const getUsers = (querystring: string = ''): Promise<{ total: number, users: IUser[] }> => {
    return Get(`${urls.USERS}${querystring}`);
};

export const getUser = (user_id: string): Promise<{ user: IUser } | any> => {
    return Get(`${urls.USERS}/${user_id}`);
};


export const saveUser = (user: IUser): Promise<{ id: string }> => {
    return Post(urls.USERS, user);
};

export const editUser = (user_id: string, user: Partial<IUser|any>): Promise<any> => {
    return Patch(`${urls.USERS}/${user_id}`, user);
};

/**
 * Videos API
 */

 export const getVideos = (querystring: string = ''): Promise<{ total: number, videos: IVideo[] }> => {
    return Get(`${urls.VIDEOS}${querystring}`);
};

export const saveVideo = (video: any, id: string): Promise<any> => {
    return Put(`${urls.VIDEOS}/${id}`, video);
};

export const newVideo = (video: Partial<IVideo>): Promise<any> => {
    return Post(`${urls.VIDEOS}`, video);
};

export const getPostUrls = (data: any): Promise<any> => {
    return Post(urls.UPLOAD, data);
};

export const getUpload = (params: any): Promise<any> => {
    return Get(`${urls.UPLOAD}`, params);
};

export const getClass = () => {
    return Get(`${urls.CLASSES}`)
};

export const uploadFile = (url: any, file: any, type: any) => {
    return axios.put(url, file, {
        headers: {'Content-Type': `${type}`},
    })
        .then(d => d.data)
        .catch(error => {
            throw error;
        });
};

/**
 * Categories API
 */

export const getCategories = (querystring: string = ''): Promise<{ total: number, categories: ICategory[] }> => {
    return Get(`${urls.CATEGORIES}${querystring}`);
};

export const getAllCategories = (): Promise<any> => {
    return Get(`${urls.CATEGORIES}/all`);
};

export const saveCategory = (category: Partial<ICategory>, id: string): Promise<any> => {
    return Put(`${urls.CATEGORIES}/${id}`, category);
};

export const newCategory = (category: Partial<ICategory>): Promise<any> => {
    return Post(`${urls.CATEGORIES}`, category);
};

/**
 * Coach API
 */

export const getUnits= (queryString: string = '') : Promise <{centers: IUnit[], total: number}> => {
    return Get(`${urls.REGISTER_UNITS}/${queryString}`);
};

export const getCoaches = (querystring: string = ''): Promise<{ total: number, users: ICoache[] }> => {
    return Get(`${urls.COACHES}${querystring}`);
};

export const getCoachees = (querystring: string = ''): Promise<{ total: number, users: ICoache[] }> => {
    return Get(`${urls.COACHEES}${querystring}`);
};

export const getCoachee = (id: string): Promise<{coacheee: ICoacheeDetails[]}> => {
    return Get(`${urls.COACHEES}/${id}`);
};

export const saveCoachee = (coacheeId: string, coachId: string, data: any): Promise<{coacheee: ICoacheeDetails[]}> => {
    return Patch(`${urls.COACHEES}/${coacheeId}/${coachId}`, data);
};

export const retryCoachee = (coacheeId: string, coachId: string): Promise<{coacheee: ICoacheeDetails[]}> => {
    return Post(`${urls.COACHEES}/retry/${coacheeId}/${coachId}`);
};

export const confirmCoachee = (coacheeId: string, coachId: string): Promise<{coacheee: ICoacheeDetails[]}> => {
    return Post(`${urls.COACHEES}/confirm/${coacheeId}/${coachId}`);
};

export const getCoachesCsv = (querystring: string = ''): Promise<{ total: number, users: ICoache[] }> => {
    return Get(`${urls.COACHES_CSV}${querystring}`);
};

export const getCoachesForEmail = (email: string = ''): Promise<any> => {
    return Get(`${urls.COACHES}/${email}`);
};

export const getCoach = (coach_id: string): Promise<{ user: ICoache } | any> => {
    return Get(`${urls.COACHES}/${coach_id}`);
};

export const saveCoach = (coach: ICoache): Promise<{ id: string }> => {
    return Post(urls.COACHES, coach);
};

export const editCoach = (coach_id: string, coach: Partial<ICoache|any>): Promise<any> => {
    return Patch(`${urls.COACHES}/${coach_id}`, coach);
};

/**
 * Scis classes API
 */

export const getUnitsScisClasses= (queryString: string = '') : Promise <{centers: IUnitScisClasses[], total: number}> => {
    return Get(`${urls.REGISTER_UNITS}/${queryString}`);
};

export const getCoachesScisClasses = (querystring: string = ''): Promise<{ total: number, users: ICoacheScisClasses[] }> => {
    return Get(`${urls.SCIS_CLASSES}/${querystring}`);
};

export const getCoachesScisClassesXlsx = (querystring: string = ''): Promise<{ total: number, users: ICoache[] }> => {
    return Get(`${urls.SCIS_CLASSES_EXPORT}${querystring}`);
};

/**
* Payments API
*/

export const listPayments = (id_coache: string): Promise<{ payments: IPayment[] }> => {
    return Get(urls.PAYMENTS(id_coache));
};

export const listAllPayments = (params?: IQueryPayments): Promise<IListPayments> => {
    return Get(urls.PAYMENTS_ALL, params);
};

export const createPayment = (id_coach: string, payment: IPaymentToCreate): Promise<{ payment: IPayment }> => {
    return Post(urls.PAYMENTS(id_coach), payment);
};

export const cancelPayment = (id_coache: string, id_payment: string, body: any): Promise<{ payment: IPayment }> => {
    return Post(urls.PAYMENT_CANCEL(id_coache, id_payment), body)
};

/**
 * Coaches Importation
 */

export const sendFormPreview = (form: IImportationForm): Promise<IPreviewInformation> => {
    return Post(urls.IMPORT_PREVIEW, form);
};

export const revalidateCoach = (coach_import: ICoachImport): Promise<ICoachPreview> => {
    return Post(urls.IMPORT_VALIDATE, coach_import);
};

export const importCoach = (coach: ICoachToSave): Promise<IPreviewInformation> => {
    return Post(urls.IMPORT_SAVE, coach);
};

/**
 * Contact Us
 */

export const getContactUsMessage = (queryString: string = '') : Promise<IMessage[]> => {
    return Get(`${urls.CONTACTS_US}${queryString}`);
};

export const getContactUsMessageComplet = (queryString: string = '') : Promise<IMessage> => {
    return Get(`${urls.CONTACTS_US}/${queryString}`);
};

/**
 * PanelControl
 */

 export const getPanelControl= (queryString: string = '') : Promise <IDashboardData> => {
    return Get(`${urls.PANEL_CONTROL}/${queryString}`);
 };


export const getPanelCoaching = (queryString: string='') : Promise <IRequest> => {
    return Get(`${urls.PANEL_CONTROL_COACHING}/${queryString}`);
};

export const getPanelGraph = () : Promise <IGraphRequest> => {
    return Get(`${urls.PANEL_CONTROL_GRAPH_BY_MOUTH}`);
};

export const getPanelGraphAllYear = () : Promise <IGraphRequestAllYear> => {
    return Get(`${urls.PANEL_CONTROL_GRAPH_ALL_YEAR}`);
};

export const getPanelGraphLastYear = () : Promise <IGraphRequest> => {
    return Get(`${urls.PANEL_CONTROL_GRAPH_LAST_YEAR}`);
};

/**
 * PanelControlClasses
 */

export const getPanelFilter = (queryString: string='') : Promise <IRequestClasses> => {
    return Get(`${urls.PANEL_CONTROL_CLASSES}/${queryString}`);
};

export const getPanelClassesUnits= () => {
    return Get(`${urls.PANEL_CONTROL_CLASSES_GET_UNITS}`)
};

export const getPanelClassesClasses = () => {
    return Get(`${urls.CLASSES}`)
};

/**
 * Profiles_Coaches
 */

export const getProfilesCoaches = ( queryString: string = '' ) : Promise <IRequestProfile> => {
    return Get(`${urls.PROFILE_COACHES}/${queryString}`);
};

/*
 * Register-Units
 */

export const getListUnits= (queryString: string = '') : Promise <IRequestUnits> => {
    return Get(`${urls.REGISTER_UNITS}/${queryString}`);
};

export const getIdUnits= (queryString: string = '') : Promise <IUnits> => {
    return Get(`${urls.REGISTER_UNITS}/${queryString}`);
};

export const insertUnits= (data: any) : Promise <any> => {
    return Post(`${urls.REGISTER_UNITS}`, data);
};

export const editUnits= (queryString: string, data: any) : Promise <any> => {
    return Post(`${urls.REGISTER_UNITS}/${queryString}`, data);
};

/**
 * URL-SHORTENERS
 */

export const getListUrls = (queryString: string = '') : Promise <any> => {
    return Get(`${urls.GET_URL_SHORTENERS}${queryString}`);
};

export const getReadCode = (queryString: string = '') : Promise <any> => {
    return Get(`${urls.GET_URL_SHORTENERS}/${queryString}`);
};

export const putAddUrl = (data: any) : Promise <any> => {
    return Put(`${urls.GET_URL_SHORTENERS}`, data);
};

export const deleteRemoveUrl = (queryString: string = '') : Promise <any> => {
    return Delete(`${urls.GET_URL_SHORTENERS}/${queryString}`);
};


export const postEditUrl= (queryString: string, data: any) : Promise <any> => {
    return Post(`${urls.GET_URL_SHORTENERS}/${queryString}`, data);
};

/**
 * Utils API
 */

export const getCenters = (): Promise<{ centers: ICenter[] }> => {
    return Get(urls.CENTERS);
};

export const getLicenseTypes = (): Promise<{ tipo_licencas: ILicenseTypes[] }> => {
    return Get(urls.LICENSE_TYPES);
};

export const getCountries = (): Promise<ICountry[]> => {
    return Get(urls.COUNTRIES);
};

export const getStates = (url: string): Promise<IStates[]> => {
    return Get(`${urlApiV1}${url}`);
};

export const getTimeZones = (): Promise<ITimezone[]> => {
    return Get(urls.TIMEZONES);
};

export const getLocales = (): Promise<ILocale[]> => {
    return Get(urls.LOCALES);
};

async function Get(url: string, params?: any, headers?: any): Promise<any> {
    try {
        return await _Get(url, headers, params);
    } catch (e) {
        if (e.response && e.response.status === 401) {
            await RefreshToken();
            return _Get(url, headers);
        }
        throw e;
    }
}

async function Post(url: string, data?: any, headers?: any): Promise<any> {
    try {
        return await _Post(url, data, headers);
    } catch (e) {
        if (e.response && e.response.status === 401) {
            await RefreshToken();
            return _Post(url, data, headers);
        }
        throw e;
    }
}

async function Patch(url: string, data?: any, headers?: any): Promise<any> {
    try {
        return await _Patch(url, data, headers);
    } catch (e) {
        if (e.response && e.response.status === 401) {
            await RefreshToken();
            return _Patch(url, data, headers);
        }
        throw e;
    }
}

/**
 * BlockList
 */

 export const getBlockList = (queryString: string = ''): Promise<{results: IBlockList[]}> => {
    return Get(`${urls.BLOCKLIST}${queryString}`);
};

export const deleteEmail = (email: string): Promise<any> => {
    return Delete(`${urls.BLOCKLIST}/${email}`);
};

/*
* Notifications List
* */

export const getNotificationsList = (queryString: string = ''): Promise<{results: INotificationsList[]}> => {
    return Get(`${urls.NOTIFICATIONS_LIST}${queryString}`);
}

export const postNewNotification = (data: IPostNotification) => {
    return Post(`${urls.NOTIFICATIONS_LIST}`, data);
}

/**
 * Admin API
 */

export const getAdminsInfo = (params: Partial<IQueryStringList>): Promise<{total: number, users: IAdmin[]}> => {
    return Get(`${urls.ADMINS}`, params);
};

export const getAdminByEmail = (email: string = ''): Promise<any> => {
    return Get(`${urls.ADMINS}/check/${email}`);
};

export const getUserPermissions = (): Promise<any> => {
    return Get(`${urls.ADMINS}/me`);
};

export const getAdminById = (id: string): Promise<any> => {
    return Get(`${urls.ADMINS}/${id}`);
}

export const getAllPermissions = (): Promise<any> => {
    return Get(`${urls.ADMINS}/permissions`);
}

export const saveAdmin = (admin: IAdmin): Promise<{id: string}> => {
    return Post(urls.ADMINS, admin);
};

export const editAdmim = (id: string, payload: {permissions: string[]}): Promise<any> => {
    return Put(`${urls.ADMINS}/${id}`, payload);
};

export const deleteAdmin = (id: string): Promise<any> => {
    return Delete(`${urls.ADMINS}/${id}`);
};

export const makeAdmin = (email: string, payload: {permissions: string[]}): Promise<any> => {
    return Put(`${urls.ADMINS}/confirm/${email}`, payload);
};
/**
 * Reports
 */

export const getCoachReports = (params: IReportQueryStringList | {}): Promise<{reports: IReport[], total: number}> => {
    return Get(urls.REPORTS, {...params, type: reportTypes.COACHES});
}

export const getExperimentalSessionReports = (params: IReportQueryStringList | {}): Promise<{reports: IReport[], total: number}> => {
    return Get(urls.REPORTS, {...params, type: reportTypes.EXPERIMENTAL_SESSION});
}

export const generateCoachReport = (): Promise<IReport> => {
    return Post(urls.REPORTS, {report_type: reportTypes.COACHES});
}

export const getCoachingHoursReports = (params: IReportQueryStringList | {}): Promise<{reports: IReport[], total: number}> => {
    return Get(urls.REPORTS, {...params, type: reportTypes.COACHING_HOURS});
}

export const generateCoachingHoursReport = (initialMonth: string, endMonth: string): Promise<IReport> => {
    return Post(urls.REPORTS, {extra_args: {from: initialMonth, to: endMonth}, report_type: reportTypes.COACHING_HOURS});
}

export const getCoachingEvaluationsReports = (params: IReportQueryStringList | {}): Promise<{reports: IReport[], total: number}> => {
    return Get(urls.REPORTS, {...params, type: reportTypes.COACHING_EVALUATION});
}

export const generateCoachingEvaluationsReport = (extra_args: IExtraArgs): Promise<IReport> => {
    return Post(urls.REPORTS, {extra_args, report_type: reportTypes.COACHING_EVALUATION});
}

export const generateExperimentalSessionReport = (extra_args: IExtraArgsEs): Promise<IReport> => {
    return Post(urls.REPORTS, {extra_args, report_type: reportTypes.EXPERIMENTAL_SESSION});
}

export const generateInvoicesReport = (initialData: string, endData: string, statusGenerate: string | undefined| null): Promise<IReport> => {
    return Post(urls.REPORTS, {extra_args: {fromDate: initialData , toDate: endData, status: statusGenerate === 'ALL' ? undefined : statusGenerate}, report_type: reportTypes.INVOICES});
}

export const getInvoicesReports = (params: IReportQueryStringList | {}): Promise<{reports: IReport[], total: number}> => {
    return Get(urls.REPORTS, {...params, type: reportTypes.INVOICES});
}

export const downloadReport = (reportId: string): Promise<IDownloadRequest> => {
    return Get(`${urls.REPORTS}/${reportId}/download`);
}

export const getReportDetails = (reportId: string): Promise<{results: IReport[]}> => {
    return Get(`${urls.REPORTS}/${reportId}`);
}

export const getUsernames = (ids: string[]): Promise<any[]> => {
    return Get(urls.USERNAMES, {userId: ids, mode: 'username'});
}

export const deleteReport = (reportId: string): Promise<{results: IReport[]}> => {
    return Delete(`${urls.REPORTS}/${reportId}`);
}

export const getCoachingCycleReports = (params: IReportQueryStringList | {}): Promise<{reports: IReport[], total: number}> => {
    return Get(urls.REPORTS, {...params, type: reportTypes.COACHING_CYCLE});
}

export const generateCoachingCycleReport = (initialMonth: string, endMonth: string): Promise<IReport> => {
    return Post(urls.REPORTS, {extra_args: {from: initialMonth, to: endMonth}, report_type: reportTypes.COACHING_CYCLE});
}

export const getMonthlyCoachingReport = (params: IReportQueryStringList | {}): Promise<{reports: IReport[], total: number}> => {
    return Get(urls.REPORTS, {...params, type: reportTypes.MONTHLY_COACHING});
}

export const generateMonthlyCoachingReport = (initialMonth: string, endMonth: string): Promise<IReport> => {
    return Post(urls.REPORTS, {extra_args: {from: initialMonth, to: endMonth}, report_type: reportTypes.MONTHLY_COACHING});
}

/**
 * Audit
 */

export const getAudits = (queryString: string = ''): Promise<{results: IAudit[]}> => {
    return Get(`${urls.AUDIT}${queryString}`);
};

export const getAuditsAction = (): Promise<{actions: string[]}> => {
    return Get(`${urls.AUDIT}/actions`);
};

export const getSalesRegistrations = (queryString: string='') : Promise <IRequest> => {
    return Get(`${urls.SALES_INTERNAL}/${queryString}`);
};

export const getSalesRegistration = (id: string='') : Promise <ISalesRegistrationItem> => {
    return Get(`${urls.SALES_INTERNAL}/${id}`);
};

export const editSalesRegistration = (id: string, payload: { status?: string, observation: string }): Promise<IEditSalesRegistrationResponse | undefined> => {
    return Put(`${urls.SALES_INTERNAL}/${id}`, payload);
};
